import React from 'react';
import style from './BountyTile.module.css';
import hack from '../../Images/hack.png';
import { useNavigate } from 'react-router-dom';

const BountyTile = ({ bounty, admin }) => {
  const navigate = useNavigate();

  const clickHandler = (e, bounty) => {
    e.preventDefault();
    navigate(`/bounty/${bounty._id}`);
    // bounty.name.replace(/\s/g, '')
  };

  const handleUser = (id) => {
    navigate(`/admin/user/${id}`);
  };

  let date = new Date(bounty.createdAt).toString();
  const result = date.split(' ');
  const newDate = `${result[2]}/${result[1]}/${result[3]}`;

  return admin ? (
    <div className={style.BountyTile}>
      <div className={style.Left}>
        <div className={style.LeftInside}>
          <img src={hack} alt='hack' />
          <div style={{ marginLeft: '20px' }}>
            <h3>{bounty.name}</h3>
            <p>Name</p>
          </div>
        </div>
        <div>
          <h3>{newDate}</h3>
          <p>Submitted At</p>
        </div>
      </div>
      <div>
        <h3>{bounty.status}</h3>
        <p>Status</p>
      </div>
      <div>
        <h3 className={style.UserClick} onClick={() => handleUser(bounty.user)}>
          {bounty.user}
        </h3>
        <p>User</p>
      </div>
      <div className={style.Button} onClick={(e) => clickHandler(e, bounty)}>
        View bounty
      </div>
    </div>
  ) : bounty.status === 'Approved' ? (
    <div className={style.BountyTile}>
      <div className={style.Left}>
        <div className={style.LeftInside}>
          <img src={hack} alt='hack' />
          <div style={{ marginLeft: '20px' }}>
            <h3>{bounty.name}</h3>
            <p>Name</p>
          </div>
        </div>
        <div>
          <h3>{bounty.max_bounty}$</h3>
          <p>Rewards up to</p>
        </div>
      </div>
      <div>
        <h3>Smart Contract, Websites and Applications, Blockchain/DLT</h3>
        <p>Technology</p>
      </div>

      <div className={style.Button} onClick={(e) => clickHandler(e, bounty)}>
        View bounty
      </div>
    </div>
  ) : null;
};

export default BountyTile;
