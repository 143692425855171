import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Homepage from './Screens/Homepage/Homepage';
import { Route, Routes, Navigate, Link } from 'react-router-dom';
import Login from './Screens/Login/Login';
import Register from './Screens/Register/Register';
import Reset from './Screens/ResetPassword/Reset';
import Learn from './Screens/Learn/Learn';
import Dashboard from './Screens/Dashboard/Dashboard';
import Explore from './Screens/Explore/Explore';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { allBounty } from './actions/bounty.action';
import Bounty from './Screens/Explore/BountyByName/Bounty';
import Howitworks from './Screens/Howitworks/Howitworks';
import Addbounty from './Screens/AddBounty/Addbounty';
import Report from './Screens/Report/Report';
import Submission from './Screens/Submission/Submission';
import MyBounty from './Screens/MyBounty/MyBounty';
import Setting from './Screens/Setting/Setting';
import Admin from './Screens/Admin/Admin';
import UpdatePassword from './Screens/ResetPassword/UpdatePassword/UpdatePassword';

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allBounty());
  }, [dispatch]);

  const user = JSON.parse(localStorage.getItem('curUser'));

  return (
    <div className='App'>
      {user != null ? (
        user.user.isAdmin ? (
          <div
            style={{
              backgroundColor: 'rgb(204, 63, 204)',
              padding: '5px 60px',
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'Right',
            }}
          >
            <Link
              to='/admin'
              style={{
                textDecoration: 'none',
                width: 'fit-content',
                textAlign: 'right',
                cursor: 'pointer',
                color: 'white',
              }}
            >
              <i className='fas fa-user' style={{ marginRight: '5px' }} /> Admin
              Panel
            </Link>
          </div>
        ) : null
      ) : null}
      <Navbar />
      <Routes>
        {/* <Route path='/*' element={<div>PAGE NOT FOUND!</div>} /> */}
        <Route exact path='/' element={<Homepage />} />
        <Route
          exact
          path='/admin/*'
          element={
            user === null ? (
              <Navigate replace to='/' />
            ) : user.user.isAdmin ? (
              <Admin />
            ) : (
              <Navigate replace to='/dashboard' />
            )
          }
        />
        <Route
          exact
          path='/login'
          element={user ? <Navigate replace to='/dashboard' /> : <Login />}
        />
        <Route exact path='/learn' element={<Learn />} />
        <Route exact path='/howitworks' element={<Howitworks />} />
        <Route
          exact
          path='/signup'
          element={user ? <Navigate replace to='/dashboard' /> : <Register />}
        />
        <Route exact path='/setting' element={<Setting />} />
        <Route exact path='/bounty' element={<MyBounty />} />
        <Route exact path='/explore' element={<Explore />} />
        <Route exact path='/bounty/:id' element={<Bounty />} />
        <Route exact path='/dashboard' element={<Dashboard />} />
        <Route exact path='/addbounty' element={<Addbounty />} />
        <Route exact path='/forget-password' element={<Reset />} />
        <Route exact path='/new-submission' element={<Report />} />
        <Route exact path='/submission/:id' element={<Submission />} />
        <Route
          exact
          path='/reset-password/:id/:token'
          element={<UpdatePassword />}
        />
      </Routes>
    </div>
  );
};

export default App;
