import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBountyById, updateBounty } from '../../../actions/bounty.action';
import style from './Bounty.module.css';
import Footer from '../../../Components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const Bounty = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('curUser'));
  const allBounty = useSelector((state) => state.getBountyByIdReducer);
  const { bounty, success, loading } = allBounty;
  const [status, setStatus] = useState('');

  const updateBountyState = useSelector((state) => state.updateBountyReducer);

  if (!updateBountyState.loading && updateBountyState.success) {
    toast.success('Update Successful!');
  } else {
    toast.error(updateBountyState.message);
  }

  const path = window.location.pathname;
  const id = path.split('/')[2];

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getBountyById(id));
    window.scrollTo(0, 0);
  }, [pathname, dispatch, id]);

  const time = bounty ? bounty.createdAt.split('T')[0] : null;

  return (
    <>
      <div className={style.Container}>
        {!loading && success ? (
          <>
            <h1>{bounty.name}</h1>
            <div className={style.Flex}>
              <div>
                <div>{time}</div>
                <div>Live Since</div>
              </div>
              <div>
                <div>NO</div>
                <div>KYC required</div>
              </div>
              <div>
                <div>${bounty.max_bounty}</div>
                <div>Maximum bounty</div>
              </div>
            </div>
            {user && user.user.isAdmin ? (
              <select
                value={status === '' ? bounty.status : status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  dispatch(updateBounty(bounty._id, e.target.value));
                }}
                style={{ marginTop: '20px' }}
                className='minimal'
              >
                <option>Pending</option>
                <option>Under review</option>
                <option>Approved</option>
              </select>
            ) : null}
            <div className={style.Background}>
              <h1>Program Overview</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${bounty.program_overview}`,
                }}
              />
            </div>
            <div className={style.Background}>
              <h1>Rewards by Threat Level</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${bounty.rewards_by_threat_level}`,
                }}
              />
              {bounty.web_and_application[0].level !== '' ? (
                <>
                  <h2>Smart Contract</h2>
                  {bounty.smart_contract.map((sm) => {
                    return (
                      <div key={sm._id} className={style.Plate}>
                        <div className={style.Border}>
                          <div>{sm.level}</div>
                          <div>Level</div>
                        </div>
                        <div>
                          <div>${sm.payout}</div>
                          <div>Payout</div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
              {bounty.web_and_application[0].level !== '' ? (
                <>
                  <h2>Web and application</h2>
                  {bounty.web_and_application.map((sm) => {
                    return (
                      <div key={sm._id} className={style.Plate}>
                        <div className={style.Border}>
                          <div>{sm.level}</div>
                          <div>Level</div>
                        </div>
                        <div>
                          <div>${sm.payout}</div>
                          <div>Payout</div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
              {bounty.blockchain[0].level !== '' ? (
                <>
                  <h2>Blockchain</h2>
                  {bounty.blockchain.map((sm) => {
                    return (
                      <div key={sm._id} className={style.Plate}>
                        <div className={style.Border}>
                          <div>{sm.level}</div>
                          <div>Level</div>
                        </div>
                        <div>
                          <div>${sm.payout}</div>
                          <div>Payout</div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>
            <div className={style.Background}>
              <h1>Assets in Scope</h1>
              {bounty.assets_in_scope.map((as) => {
                return (
                  <div key={as._id} className={style.Assets}>
                    <div>
                      <div>{as.target}</div>
                      <div>Target</div>
                    </div>
                    <div>
                      <div>${as.type}</div>
                      <div>Type</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={style.Background}>
              <h1>Impact in scope</h1>
              <p>
                Only the following impacts are accepted within this bug bounty
                program. All other impacts are not considered as in-scope, even
                if they affect something in the assets in scope table.
              </p>
              {bounty.impact_in_scope.smart_contract[0].impact !== '' ? (
                <>
                  <h3>Smart Contract</h3>
                  {bounty.impact_in_scope.smart_contract.map((sm) => {
                    return (
                      <div key={sm._id} className={style.Impact}>
                        <div>
                          <div>{sm.impact}</div>
                          <div>Impact</div>
                        </div>
                        <div>{sm.level}</div>
                      </div>
                    );
                  })}
                </>
              ) : null}
              {bounty.impact_in_scope.web_and_application[0].impact !== '' ? (
                <>
                  <h3>Web and application</h3>
                  {bounty.impact_in_scope.web_and_application.map((sm) => {
                    return (
                      <div key={sm._id} className={style.Impact}>
                        <div>
                          <div>{sm.impact}</div>
                          <div>Impact</div>
                        </div>
                        <div>{sm.level}</div>
                      </div>
                    );
                  })}
                </>
              ) : null}
              {bounty.impact_in_scope.blockchain[0].impact !== '' ? (
                <>
                  <h3>Blockchain</h3>
                  {bounty.impact_in_scope.blockchain.map((sm) => {
                    return (
                      <div key={sm._id} className={style.Impact}>
                        <div>
                          <div>{sm.impact}</div>
                          <div>Impact</div>
                        </div>
                        <div>{sm.level}</div>
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>
            <div className={style.Background}>
              <h1>Out of scope & Rules</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${bounty.out_of_scope}`,
                }}
              />
            </div>
          </>
        ) : (
          <div className={style.Center}>
            <Oval
              ariaLabel='loading-indicator'
              height={80}
              width={80}
              strokeWidth={5}
              color='white'
              secondaryColor='purple'
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Bounty;
