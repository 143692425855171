import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../../actions/user.action';
import { Oval } from 'react-loader-spinner';
import style from './Users.module.css';
import { useNavigate } from 'react-router-dom';

const Users = () => {
  const dispatch = useDispatch();
  const allUser = useSelector((state) => state.getAllUsersReducer);
  const { loading, success, users } = allUser;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleUser = (id) => {
    navigate(`/admin/user/${id}`);
  };

  return (
    <>
      <h1 style={{ color: 'rgb(206, 144, 206)' }}>Users</h1>
      <div className={style.Users}>
        {!loading && success ? (
          users.users.length !== 0 ? (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Admin</th>
                </tr>
              </thead>
              <tbody>
                {users.users
                  .slice(0)
                  .reverse()
                  .map((user) => {
                    return (
                      <tr key={user._id}>
                        <td
                          className={style.UserClick}
                          onClick={() => handleUser(user._id)}
                        >
                          {user._id}
                        </td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.isAdmin ? 'Yes' : 'No'}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div className={style.Center} style={{ fontWeight: '500' }}>
              No user found!
            </div>
          )
        ) : (
          <div className={style.Center}>
            <Oval
              ariaLabel='loading-indicator'
              height={80}
              width={80}
              strokeWidth={5}
              color='white'
              secondaryColor='purple'
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Users;
