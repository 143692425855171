import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  userLoginReducer,
  userRegisterReducer,
  usernameUpdateReducer,
  userpassUpdateReducer,
  getAllUsersReducer,
  getUserByIdReducer,
  updateUserReducer,
  updateLoginUserReducer,
} from './reducers/user.reducers';

import {
  userBountyReducer,
  getAllBountyReducer,
  addBountyReducer,
  getBountyByIdReducer,
  updateBountyReducer,
} from './reducers/bounty.reducers';

import {
  userReportReducer,
  addReportReducer,
  getReportByIdReducer,
  getAllReportReducer,
  updateReportReducer,
} from './reducers/report.reducers';

import { getAllLoginHistoryReducer } from './reducers/loginhistory.reducers';

const composeEnhancers = composeWithDevTools({});

const currUser = localStorage.getItem('curUser')
  ? JSON.parse(localStorage.getItem('curUser'))
  : null;

const initialState = {
  userLoginReducer: {
    curUser: currUser,
  },
  getAllBountyReducer: {
    bounty: [],
  },
};

const rootReducer = combineReducers({
  userLoginReducer,
  userRegisterReducer,
  userBountyReducer,
  userReportReducer,
  getAllBountyReducer,
  addBountyReducer,
  addReportReducer,
  getBountyByIdReducer,
  getReportByIdReducer,
  usernameUpdateReducer,
  userpassUpdateReducer,
  getAllUsersReducer,
  updateBountyReducer,
  getAllReportReducer,
  getAllLoginHistoryReducer,
  updateReportReducer,
  getUserByIdReducer,
  updateUserReducer,
  updateLoginUserReducer,
});

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
