import React, { useState } from 'react';
import { toast } from 'react-toastify';
import style from './Setting.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { usernameUpdate, userpasswordUpdate } from '../../actions/user.action';
import axios from 'axios';

const Setting = () => {
  const dispatch = useDispatch();
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [userNameShow, setUserNameShow] = useState(false);
  const [loginhistoryshow, setloginhistoryshow] = useState(false);
  const [loadLoginHistory, setLoadLoginHistory] = useState(false);
  const user = JSON.parse(localStorage.getItem('curUser'));
  const [userPasswordShow, setUserPasswordShow] = useState(false);
  const [loginDetails, setLoginDetails] = useState({});

  const passUpdateState = useSelector((state) => state.userpassUpdateReducer);
  const nameUpdateState = useSelector((state) => state.usernameUpdateReducer);

  const { loading, success, message } = passUpdateState;

  const [username, setUsername] = useState(user.user.name);

  const updateUsernameHandle = () => {
    if (username) {
      if (username.length < 2) {
        toast.error('Minimum length 2!');
      } else {
        dispatch(usernameUpdate(user.user._id, username));
      }
    } else {
      toast.error('Please fill the required!');
    }
  };

  const updatePasswordHandle = () => {
    if (oldPass && newPass) {
      if (newPass.length < 6) {
        toast.error('Password min length 6!');
      } else {
        const data = {
          oldPass,
          newPass,
        };
        dispatch(userpasswordUpdate(user.user._id, data));
        setOldPass('');
        setNewPass('');
      }
    } else {
      toast.error('Please fill the required!');
    }
  };

  if (!nameUpdateState.loading && nameUpdateState.success) {
    toast.success('Username Updated!');
  } else if (
    nameUpdateState.loading === false &&
    nameUpdateState.success === false
  ) {
    toast.error(message);
  }

  if (!loading && success) {
    toast.success('Password Updated!');
  } else {
    toast.error(message);
  }

  const getloginDetails = async (id) => {
    await axios
      .get(`/loginhistory/${id}`, {
        headers: { 'Access-Control-Allow-Origin': '*' },
        withCredentials: true,
      })
      .then((res) => {
        setLoginDetails(res.data);
        setLoadLoginHistory(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={style.Setting}>
      <p>Setting</p>
      <div style={{ backgroundColor: '#262626', borderRadius: '5px' }}>
        <div className={style.Email}>
          <p>Email</p>
          <p style={{ color: 'rgb(165, 171, 177)' }}>{user.user.email}</p>
        </div>
        <div className={style.Username}>
          <div className={style.Flex}>
            <p>Username</p>
            {!userNameShow ? (
              <div
                onClick={() => setUserNameShow(!userNameShow)}
                className={style.Button}
              >
                Change
              </div>
            ) : null}
          </div>
          {userNameShow ? (
            <div className={style.username_bottom}>
              <input
                placeholder='Username'
                type='text'
                value={username}
                onChange={(e) => {
                  e.preventDefault();
                  setUsername(e.target.value);
                }}
              />
              <div>
                <div
                  onClick={() => setUserNameShow(!userNameShow)}
                  className={style.ButtonDark}
                >
                  Cancel
                </div>
                <div
                  onClick={updateUsernameHandle}
                  className={`${
                    username !== user.user.name
                      ? style.ButtonDark
                      : style.ButtonDisable
                  }`}
                >
                  Update
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={style.Password}>
          <div className={style.Flex}>
            <p>Password</p>
            {!userPasswordShow ? (
              <div
                onClick={() => setUserPasswordShow(!userPasswordShow)}
                className={style.Button}
              >
                Change
              </div>
            ) : null}
          </div>
          {userPasswordShow ? (
            <div className={style.username_bottom}>
              <input
                value={oldPass}
                onChange={(e) => setOldPass(e.target.value)}
                placeholder='Old Password'
                type='password'
              />
              <input
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
                placeholder='New Password'
                type='password'
              />
              <div>
                <div
                  onClick={() => setUserPasswordShow(!userPasswordShow)}
                  className={style.ButtonDark}
                >
                  Cancel
                </div>
                <div
                  onClick={updatePasswordHandle}
                  className={style.ButtonDark}
                >
                  Update
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={style.LoginHistoryContainer}>
          <div className={style.Flex}>
            <p>
              Login History{' '}
              <span style={{ fontSize: '12px', color: 'rgb(236, 195, 236)' }}>
                (Last 5)
              </span>
            </p>
            <div
              onClick={() => {
                setloginhistoryshow(!loginhistoryshow);
                !loginhistoryshow ? (
                  getloginDetails(user.user._id)
                ) : (
                  <div></div>
                );
              }}
              className={style.Button}
            >
              {loginhistoryshow ? 'Cancel' : 'Show'}
            </div>
          </div>
          {loginhistoryshow ? (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>IP</th>
                  <th>Location</th>
                  <th>Browser</th>
                  <th>OS</th>
                </tr>
              </thead>
              <tbody>
                {loadLoginHistory &&
                  loginDetails.userLoginHistory.loginHistory
                    .slice(-5)
                    .reverse()
                    .map((his) => {
                      let date = new Date(his.createdAt).toString();
                      const result = date.split(' ');
                      const newDate = `${result[2]}/${result[1]}/${result[3]}`;
                      const newTime = result[4];

                      return (
                        <tr key={his._id}>
                          <td>
                            <div className={style.Date}>{newDate}</div>
                            <div
                              className={style.Time}
                            >{`Time - ${newTime}`}</div>
                          </td>
                          <td>{his.loginHistory.ip}</td>
                          <td>{`${his.loginHistory.location}-${his.loginHistory.country}`}</td>
                          <td>{his.loginHistory.browserName}</td>
                          <td>{his.loginHistory.os}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          ) : (
            <div></div>
          )}
          {/* <div className={style.Flex}>
            <div className={style.LoginHistoryDate}>
              <p>Date</p>
              <p>1 week ago</p>
            </div>
            <div className={style.LoginHistoryDate}>
              <p>Username</p>
              <p>Singh</p>
            </div>
            <div className={style.LoginHistoryDate}>
              <p>IP</p>
              <p>192.168.0.1</p>
            </div>
            <div className={style.LoginHistoryDate}>
              <p>Location</p>
              <p>Nangloi-IN</p>
            </div>
            <div className={style.LoginHistoryDate}>
              <p>Browser</p>
              <p>Chrome</p>
            </div>
            <div className={style.LoginHistoryDate}>
              <p>OS</p>
              <p>Windows 10</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Setting;
