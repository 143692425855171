import React, { useState } from 'react';
import style from './Reset.module.css';
import { Link } from 'react-router-dom';
import { ValidateEmail } from '../../utils/ValidateEmail';
import axios from 'axios';
import { toast } from 'react-toastify';

const Reset = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    if (!ValidateEmail(email)) {
      return;
    }
    await axios
      .post(
        '/user/forgot-password',
        { email },
        {
          headers: { 'Access-Control-Allow-Origin': '*' },
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <div className={style.Reset}>
      <p>Reset your password</p>
      <div className={style.details}>
        <p>Fill in your email address below and we'll send you a reset link.</p>
      </div>
      <div className={style.section}>
        <p>Email</p>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type='email'
          placeholder='hunter@hackerbro.in'
        />
      </div>
      <div onClick={handleSubmit} className={style.Button}>
        Send reset link
      </div>

      <div className={style.Bottom}>
        <span>Suddenly remembered your password?</span>
        <Link
          to='/login'
          className='link'
          style={{ color: 'rgb(161, 35, 161)' }}
        >
          {' '}
          Log in.
        </Link>
      </div>
    </div>
  );
};

export default Reset;
