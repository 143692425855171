import React from 'react';
import Footer from '../../Components/Footer/Footer';
import Main from './Main/Main';
import style from './Homepage.module.css';
import Trust from './Trust/Trust';
import NewsLetter from '../../Components/NewsLetter/NewsLetter';
import Bounty from './Bounty/Bounty';
import HackerProject from './HackerProject/HackerProject';

const Homepage = () => {
  return (
    <>
      <div className={style.Homepage}>
        <Main />
        <Trust />
      </div>
      <NewsLetter />
      <Bounty />
      <HackerProject />
      <Footer />
    </>
  );
};

export default Homepage;
