import axios from 'axios';
axios.defaults.baseURL = 'http://bbtapi.hackerbro.in/api/';
axios.defaults.withCredentials = false;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

export const userBounty = (userId) => async (dispatch) => {
  dispatch({ type: 'USER_BOUNTY_REQUEST' });
  try {
    const res = await axios.get(`/bounty/getByUserId/${userId}`, {
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    dispatch({ type: 'USER_BOUNTY_SUCCESS', payload: res.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: 'USER_BOUNTY_ERROR',
      payload: error,
    });
  }
};

export const allBounty = () => async (dispatch) => {
  dispatch({ type: 'GET_ALL_BOUNTY_REQUEST' });
  try {
    const res = await axios.get(`/bounty`, {
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    dispatch({ type: 'GET_ALL_BOUNTY_SUCCESS', payload: res.data.bounties });
  } catch (error) {
    console.log(error);
    dispatch({
      type: 'GET_ALL_BOUNTY_ERROR',
      payload: error,
    });
  }
};

export const bountyRegister = (bounty) => async (dispatch) => {
  dispatch({ type: 'ADD_BOUNTY_REQUEST' });
  try {
    await axios.post(`/bounty/addbounty/${bounty.user}`, bounty, {
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    dispatch({ type: 'ADD_BOUNTY_SUCCESS' });
  } catch (error) {
    dispatch({
      type: 'ADD_BOUNTY_ERROR',
      payload: error.response.data.message,
    });
  }
};

export const getBountyById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_BOUNTY_BY_ID_REQUEST' });
  try {
    const res = await axios.get(`/bounty/bountyById/${id}`, {
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    dispatch({ type: 'GET_BOUNTY_BY_ID_SUCCESS', payload: res.data.bounty });
  } catch (error) {
    console.log(error);
    dispatch({
      type: 'GET_BOUNTY_BY_ID_ERROR',
      payload: error.response.data.message,
    });
  }
};

export const updateBounty = (id, status) => async (dispatch) => {
  dispatch({ type: 'UPDATE_BOUNTY_REQUEST' });
  try {
    const res = await axios.post(
      `/bounty/updatebounty/${id}`,
      { status },
      {
        headers: { 'Access-Control-Allow-Origin': '*' },
        withCredentials: true,
      }
    );
    dispatch({ type: 'UPDATE_BOUNTY_SUCCESS', payload: res.data.bounty });
  } catch (error) {
    console.log(error);
    dispatch({
      type: 'UPDATE_BOUNTY_ERROR',
      payload: error.response.data.message,
    });
  }
  setTimeout(() => dispatch({ type: 'UPDATE_BOUNTY_RESET' }), 1000);
};
