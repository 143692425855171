import React from 'react';
import Footer from '../../Components/Footer/Footer';
import style from './Howitworks.module.css';

const Hacker = () => {
  return (
    <>
      <div className={style.Howitworks}>
        <div className={style.Left}>
          <div>How it Works - Hackers</div>
        </div>
      </div>
      <div className={style.Text}>
        <p>
          What is blockchain? What are smart contracts? How do smart contracts
          work? What kinds of vulnerabilities exist in smart contracts, and most
          importantly, how do you find them?
        </p>
        <p>
          This is an introductory guide and an external resources page (v0.1)
          for learning about the basics of blockchain, smart contracts, and
          smart contract security on a more conceptual level. The goal is to
          provide a launching pad for hackers, researchers, devs, and companies
          to quickly get up to speed on the technology, so they can start
          hunting for bugs and collecting bounties. Since the field is changing
          by the day, this guide will be updated regularly. Resources that
          expand on the various topics are available at the bottom of the page.
          If you have feedback or would like to help contribute, please send an
          email to support@hackerbro.in.
        </p>
        <p>
          The fundamental idea of Blockchain is that it functions as a public,
          decentralized database that records transactions, which are chained
          together as blocks, in a permanent way. There are different kinds of
          blockchains on the market, and Bitcoin and Ethereum are two of the
          most important. The most relevant blockchain to us is Ethereum, since
          that’s the blockchain which virtually all smart contracts and
          distributed applications (DApps) use, so that’s what we’ll focus on
          here.
        </p>
        <p>
          A smart contract is a self-executing contract, where the terms are
          written in code. As smart contract inventor Nick Szabo defined the
          concept in a 1996 whitepaper, “A smart contract is a computerized
          transaction protocol that executes the terms of a contract. The
          general objectives of smart contract design are to satisfy common
          contractual conditions (such as payment terms, liens, confidentiality,
          and even enforcement), minimize exceptions both malicious and
          accidental, and minimize the need for trusted intermediaries.”
        </p>
        <p>
          A smart contract is a self-executing contract, where the terms are
          written in code. As smart contract inventor Nick Szabo defined the
          concept in a 1996 whitepaper, “A smart contract is a computerized
          transaction protocol that executes the terms of a contract. The
          general objectives of smart contract design are to satisfy common
          contractual conditions (such as payment terms, liens, confidentiality,
          and even enforcement), minimize exceptions both malicious and
          accidental, and minimize the need for trusted intermediaries.”
        </p>
        <p>
          A smart contract is a self-executing contract, where the terms are
          written in code. As smart contract inventor Nick Szabo defined the
          concept in a 1996 whitepaper, “A smart contract is a computerized
          transaction protocol that executes the terms of a contract. The
          general objectives of smart contract design are to satisfy common
          contractual conditions (such as payment terms, liens, confidentiality,
          and even enforcement), minimize exceptions both malicious and
          accidental, and minimize the need for trusted intermediaries.”
        </p>
        <p>
          101 Blockchains has a helpful reference graphic and article which
          explains what smart contracts are, how they work, what the benefits
          are, and what they might be used for.
        </p>
        <h1>Ownership</h1>
        <p>
          A smart contract is a self-executing contract, where the terms are
          written in code. As smart contract inventor Nick Szabo defined the
          concept in a 1996 whitepaper, “A smart contract is a computerized
          transaction protocol that executes the terms of a contract. The
          general objectives of smart contract design are to satisfy common
          contractual conditions (such as payment terms, liens, confidentiality,
          and even enforcement), minimize exceptions both malicious and
          accidental, and minimize the need for trusted intermediaries.”
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Hacker;
