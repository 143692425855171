export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case 'USER_REGISTER_REQUEST':
      return {
        loading: true,
      };
    case 'USER_REGISTER_SUCCESS':
      return {
        loading: false,
        success: true,
      };
    case 'USER_REGISTER_FAILED':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    case 'USER_REGISTER_RESET':
      return {};
    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case 'USER_LOGIN_REQUEST':
      return {
        loading: true,
      };
    case 'USER_LOGIN_SUCCESS':
      return {
        loading: false,
        success: true,
        curUser: action.payload.user,
        token: action.payload.token,
        message: action.payload.message,
      };
    case 'USER_LOGIN_FAILED':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    case 'USER_LOGIN_RESET':
      return {};
    default:
      return state;
  }
};

const initialState = {
  users: [],
};

export const getAllUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALL_USERS_REQUEST':
      return {
        loading: true,
      };
    case 'ALL_USERS_SUCCESS':
      return {
        loading: false,
        success: true,
        users: action.payload,
      };
    case 'ALL_USERS_FAILED':
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const getUserByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_USER_REQUEST':
      return {
        loading: true,
      };
    case 'GET_USER_SUCCESS':
      return {
        loading: false,
        success: true,
        user: action.payload,
      };
    case 'GET_USER_FAILED':
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const usernameUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'USERNAME_UPDATE_REQUEST':
      return {
        loading: true,
      };
    case 'USERNAME_UPDATE_SUCCESS':
      return {
        loading: false,
        success: true,
      };
    case 'USERNAME_UPDATE_FAILED':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    case 'USERNAME_RESET_REQUEST':
      return {};
    default:
      return state;
  }
};
export const userpassUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'USERPASS_UPDATE_REQUEST':
      return {
        loading: true,
      };
    case 'USERPASS_UPDATE_SUCCESS':
      return {
        loading: false,
        success: true,
        message: action.payload,
      };
    case 'USERPASS_UPDATE_FAILED':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    case 'USERPASS_RESET_REQUEST':
      return {};
    default:
      return state;
  }
};

export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_USER_REQUEST':
      return {
        loading: true,
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        loading: false,
        success: true,
        message: action.payload,
      };
    case 'UPDATE_USER_FAILED':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    case 'UPDATE_USER_RESET':
      return {};
    default:
      return state;
  }
};
export const updateLoginUserReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_LOGIN_USER_REQUEST':
      return {
        loading: true,
      };
    case 'UPDATE_LOGIN_USER_SUCCESS':
      return {
        loading: false,
        success: true,
        user: action.payload.user,
        message: action.payload.message,
      };
    case 'UPDATE_LOGIN_USER_FAILED':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    case 'UPDATE_LOGIN_USER_RESET':
      return {};
    default:
      return state;
  }
};
