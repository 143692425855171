import React from 'react';
import style from './NewsLetter.module.css';

const NewsLetter = () => {
  return (
    <div className={style.NewsLetter}>
      <div className={style.Left}>
        <p>
          Hackers subscribed to our newsletter are <span>35.8%</span> more
          likely to earn a Bounty
        </p>
      </div>

      <div className={style.Right}>
        <input type='text' placeholder='Your email, Please' />
        <span>Prove It</span>
      </div>
    </div>
  );
};

export default NewsLetter;
