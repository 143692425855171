import React from 'react';
import style from './Main.module.css';

const Main = () => {
  return (
    <div className={style.Main}>
      <div className={style.Left}>
        <div className={style.Content}>
          Web3's leading bug bounty platform, protecting $100 billion in user
          funds
        </div>
        <div className={style.LeftBottom}>
          <div className={style.ButtonF}>Explore Bounties</div>
          <div className={style.Button}>Get protected</div>
        </div>
      </div>
      <div className={style.Right}>
        <div>
          <p className={style.Up}>$40,000,000+</p>
          <p className={style.Down}>in bounties paid out</p>
        </div>
        <div>
          <p className={style.Up}>$133,865,046</p>
          <p className={style.Down}>in bounties available</p>
        </div>
        <div>
          <p className={style.Up}>$20,000,000,000+</p>
          <p className={style.Down}>in hack damage averted</p>
        </div>
      </div>
    </div>
  );
};

export default Main;
