import axios from 'axios';

export const userReport = (userId) => async (dispatch) => {
  dispatch({ type: 'USER_REPORT_REQUEST' });
  try {
    const res = await axios.get(
      `/report/allreportByuserId/${userId}`,
      {
        headers: { 'Access-Control-Allow-Origin': '*' },
        withCredentials: true,
      }
    );
    dispatch({ type: 'USER_REPORT_SUCCESS', payload: res.data });
  } catch (error) {
    dispatch({
      type: 'USER_REPORT_FAILED',
      payload: error,
    });
  }
};

export const reportRegister = (report) => async (dispatch) => {
  dispatch({ type: 'ADD_REPORT_REQUEST' });
  try {
    await axios.post(
      `/report/addreport/${report.user}`,
      report,
      {
        headers: { 'Access-Control-Allow-Origin': '*' },
        withCredentials: true,
      }
    );
    dispatch({ type: 'ADD_REPORT_SUCCESS' });
  } catch (error) {
    dispatch({
      type: 'ADD_REPORT_ERROR',
      payload: error.response.data.message,
    });
  }
};

export const getReportById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_REPORT_BY_ID_REQUEST' });
  try {
    const res = await axios.get(
      `/report/reportById/${id}`,
      {
        headers: { 'Access-Control-Allow-Origin': '*' },
        withCredentials: true,
      }
    );
    dispatch({ type: 'GET_REPORT_BY_ID_SUCCESS', payload: res.data.report });
  } catch (error) {
    dispatch({
      type: 'GET_REPORT_BY_ID_ERROR',
      payload: error.response.data.message,
    });
  }
};

export const allReport = () => async (dispatch) => {
  dispatch({ type: 'GET_ALL_REPORT_REQUEST' });
  try {
    const res = await axios.get(`/report`, {
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    dispatch({ type: 'GET_ALL_REPORT_SUCCESS', payload: res.data.reports });
  } catch (error) {
    console.log(error);
    dispatch({
      type: 'GET_ALL_REPORT_ERROR',
      payload: error,
    });
  }
};

export const updateReport = (id, status) => async (dispatch) => {
  dispatch({ type: 'UPDATE_REPORT_REQUEST' });
  try {
    const res = await axios.put(
      `/report/updatereport/${id}`,
      { status },
      { headers: { 'Access-Control-Allow-Origin': '*' }, withCredentials: true }
    );
    dispatch({ type: 'UPDATE_REPORT_SUCCESS', payload: res.data.message });
  } catch (error) {
    console.log(error);
    dispatch({
      type: 'UPDATE_REPORT_ERROR',
      payload: error.response.data.message,
    });
  }
  setTimeout(() => dispatch({ type: 'UPDATE_REPORT_RESET' }), 1000);
};
