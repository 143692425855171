import React from 'react';
import chain from '../../../Images/chainlink-logo.png';
import nexus from '../../../Images/nexus-mutual.svg';
import sun from '../../../Images/sushiswap.svg';
import synthetix from '../../../Images/synthetix.svg';
import style from './Trust.module.css';

const Trust = () => {
  return (
    <>
      <div className={style.Title}>Trusted By</div>
      <div className={style.Trust}>
        <div className={style.Image}>
          <img src={chain} alt='some pic' />
        </div>
        <div className={style.Image}>
          <img src={nexus} alt='some pic' />
        </div>
        <div className={style.Image}>
          <img src={sun} alt='some pic' />
        </div>
        <div className={style.Image}>
          <img src={synthetix} alt='some pic' />
        </div>
      </div>
    </>
  );
};
export default Trust;
