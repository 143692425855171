import axios from 'axios';

export const getAllLoginHistory = () => async (dispatch) => {
  dispatch({ type: 'GET_ALL_USERS_HISTORY_REQUEST' });
  try {
    const res = await axios.get(
      `/loginhistory/all`,
      {
        headers: { 'Access-Control-Allow-Origin': '*' },
        withCredentials: true,
      }
    );
    dispatch({
      type: 'GET_ALL_USERS_HISTORY_SUCCESS',
      payload: res.data.loginHistory,
    });
  } catch (error) {
    dispatch({
      type: 'GET_ALL_USERS_HISTORY_FAILED',
      payload: error,
    });
  }
};
