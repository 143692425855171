import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from './UpdatePassword.module.css';

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');

  const id = window.location.pathname.split('/')[2];
  const token = window.location.pathname.split('/')[3];

  const handleSubmit = async () => {
    if (password.length < 6 || password === '') {
      return toast.error('Password min length 6!');
    }
    await axios
      .post(
        `/user/reset-password/${id}/${token}`,
        { password },
        {
          headers: { 'Access-Control-Allow-Origin': '*' },
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        navigate('/login');
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className={style.UpdatePassword}>
      <p>Create new password</p>
      <div className={style.details}>
        <p>Fill your newly password.</p>
      </div>
      <div className={style.section}>
        <p>Password</p>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type='password'
          placeholder='password'
        />
      </div>
      <div onClick={handleSubmit} className={style.Button}>
        Submit
      </div>

      <div className={style.Bottom}>
        <span>Suddenly remembered your password?</span>
        <Link to='/login'> Log in.</Link>
      </div>
    </div>
  );
};

export default UpdatePassword;
