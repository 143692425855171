import axios from 'axios';
import Cookie from 'universal-cookie';
const cookies = new Cookie();

export const userRegister = (user) => async (dispatch) => {
  dispatch({ type: 'USER_REGISTER_REQUEST' });
  try {
    await axios.post(`/user/signup`, user, {
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    dispatch({ type: 'USER_REGISTER_SUCCESS' });
  } catch (error) {
    dispatch({
      type: 'USER_REGISTER_FAILED',
      payload: error.response.data.message,
    });
  }
  setTimeout(() => dispatch({ type: 'USER_REGISTER_RESET' }), 1000);
};

export const userLogin = (user) => async (dispatch) => {
  dispatch({ type: 'USER_LOGIN_REQUEST' });
  try {
    const res = await axios.post(
      `/user/login`,
      user,
      { headers: { 'Access-Control-Allow-Origin': '*' }, withCredentials: true }
    );
    dispatch({ type: 'USER_LOGIN_SUCCESS', payload: res.data });
    localStorage.setItem('curUser', JSON.stringify(res.data));
  } catch (error) {
    dispatch({
      type: 'USER_LOGIN_FAILED',
      payload: error.response.data.message,
    });
  }
  setTimeout(() => dispatch({ type: 'USER_LOGIN_RESET' }), 1500);
};

export const usernameUpdate = (id, name) => async (dispatch) => {
  dispatch({ type: 'USERNAME_UPDATE_REQUEST' });
  try {
    const res = await axios.post(
      `/user/updateusername/${id}`,
      { name },
      { headers: { 'Access-Control-Allow-Origin': '*' }, withCredentials: true }
    );
    dispatch({ type: 'USERNAME_UPDATE_SUCCESS', payload: res.data });
    const user = JSON.parse(localStorage.getItem('curUser'));
    localStorage.setItem(
      'curUser',
      JSON.stringify({ user: res.data.user, token: user.token })
    );
  } catch (error) {
    dispatch({
      type: 'USERNAME_UPDATE_FAILED',
      payload: error,
    });
  }
  setTimeout(() => dispatch({ type: 'USERNAME_RESET_REQUEST' }), 1000);
};

export const userpasswordUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: 'USERPASS_UPDATE_REQUEST' });
  try {
    const res = await axios.post(
      `/user/updatepassword/${id}`,
      data,
      { headers: { 'Access-Control-Allow-Origin': '*' }, withCredentials: true }
    );
    dispatch({ type: 'USERPASS_UPDATE_SUCCESS', payload: res.data });
  } catch (error) {
    dispatch({
      type: 'USERPASS_UPDATE_FAILED',
      payload: error.response.data.message,
    });
  }
  setTimeout(() => dispatch({ type: 'USERPASS_RESET_REQUEST' }), 1000);
};

export const updateUser = (id, data) => async (dispatch) => {
  dispatch({ type: 'UPDATE_USER_REQUEST' });
  try {
    const res = await axios.post(
      `/user/updateuser/${id}`,
      data,
      { headers: { 'Access-Control-Allow-Origin': '*' }, withCredentials: true }
    );
    dispatch({ type: 'UPDATE_USER_SUCCESS', payload: res.data.message });
  } catch (error) {
    dispatch({
      type: 'UPDATE_USER_FAILED',
      payload: error,
    });
  }
  setTimeout(() => dispatch({ type: 'UPDATE_USER_RESET' }), 1000);
};

export const updateLoginUser = (id, data) => async (dispatch) => {
  dispatch({ type: 'UPDATE_LOGIN_USER_REQUEST' });

  await axios
    .post(`/user/updateuser/${id}`, data, {
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    })
    .then((res) => {
      dispatch({ type: 'UPDATE_LOGIN_USER_SUCCESS', payload: res.data });
      const user = JSON.parse(localStorage.getItem('curUser'));
      localStorage.setItem(
        'curUser',
        JSON.stringify({ user: res.data.user, token: user.token })
      );
      setTimeout(() => dispatch({ type: 'UPDATE_LOGIN_USER_RESET' }), 1000);
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_LOGIN_USER_FAILED',
        payload: error,
      });
    });
};

export const userLogout = () => (dispatch) => {
  dispatch({ type: 'USER_LOGOUT' });
  localStorage.removeItem('curUser');
  cookies.remove('access_token');
  window.location.href = `/login`;
};

export const getAllUsers = () => async (dispatch) => {
  dispatch({ type: 'ALL_USERS_REQUEST' });
  try {
    const res = await axios.get('/user', {
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    dispatch({ type: 'ALL_USERS_SUCCESS', payload: res.data });
  } catch (error) {
    dispatch({
      type: 'ALL_USERS_FAILED',
      payload: error.response.data.message,
    });
  }
};

export const getUserById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_USER_REQUEST' });
  try {
    const res = await axios.get(
      `/user/getuser/${id}`,
      {
        headers: { 'Access-Control-Allow-Origin': '*' },
        withCredentials: true,
      }
    );
    dispatch({ type: 'GET_USER_SUCCESS', payload: res.data.user });
  } catch (error) {
    dispatch({
      type: 'GET_USER_FAILED',
      payload: error.response.data.message,
    });
  }
};
