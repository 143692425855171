import { toast } from 'react-toastify';

const regEx =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const ValidateEmail = (mail) => {
  if (mail.toLowerCase().match(regEx)) {
    return true;
  }
  toast.error('Not a valid email!');
  return false;
};
