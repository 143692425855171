import React, { useRef, useState } from 'react';
import style from './Report.module.css';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { Oval } from 'react-loader-spinner';
import { getBountyById } from '../../actions/bounty.action';
import TextEditor from '../../Components/Editor/TextEditor';
import { reportRegister } from '../../actions/report.action';
import Footer from '../../Components/Footer/Footer';
import { toast } from 'react-toastify';

const Report = () => {
  const pocRef = useRef(null);
  const dispatch = useDispatch();
  const descriptionRef = useRef(null);
  const [title, setTitle] = useState('');
  const [wallet, setWallet] = useState('');
  const [Impact, setImpact] = useState([]);
  const [showTarget, setTarget] = useState(false);
  const [severity, setSeverity] = useState('Low');
  const [imp, setImp] = useState('smart_contract');
  const [targetntype, setTargetNType] = useState({});
  const user = JSON.parse(localStorage.getItem('curUser'));
  const [impText, setimpText] = useState('Smart Contract');
  const [selectedOption, setSelectedOption] = useState(null);

  const bountyByID = useSelector((state) => {
    return state.getBountyByIdReducer;
  });

  const options = [];

  const { bounty, loading, success } = useSelector(
    (state) => state.getAllBountyReducer
  );

  if (!loading && success) {
    bounty.forEach((single_bounty) => {
      if (single_bounty.status === 'Approved') {
        return options.push({
          value: `${single_bounty.name}`,
          label: `${single_bounty.name}`,
          id: `${single_bounty._id}`,
        });
      }
    });
  }

  const changeInput = (data) => {
    setSelectedOption(data.value);
    setTarget(true);
    dispatch(getBountyById(data.id));
  };

  const handleImpact = (e) => {
    var Impact_array = [...Impact];
    if (e.target.checked) {
      Impact_array = [...Impact, e.target.value];
    } else {
      Impact_array.splice(Impact.indexOf(e.target.value), 1);
    }
    setImpact(Impact_array);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'rgb(161, 35, 161)' : 'white',
    }),
  };

  const setTargetnType = (target, type) => {
    setTargetNType({ target, type });
    if (type.toLowerCase().includes('blockchain')) {
      setImp('blockchain');
      setimpText('Blockchain/DLT');
    } else if (type.toLowerCase().includes('smart')) {
      setImp('smart_contract');
      setimpText('Smart Contract');
    } else if (type.toLowerCase().includes('web')) {
      setImp('web_and_application');
      setimpText('Web and application');
    }
  };

  const submitHandle = (e) => {
    if (
      selectedOption &&
      targetntype &&
      Impact &&
      severity &&
      wallet &&
      title &&
      descriptionRef.current.getContent() &&
      pocRef.current.getContent()
    ) {
      const report = {
        program: selectedOption,
        target: targetntype,
        impact: Impact,
        severity: severity,
        report: {
          title: title,
          desc: descriptionRef.current.getContent(),
          poc: pocRef.current.getContent(),
        },
        upi: wallet,
        user: user.user._id,
        bounty: bountyByID.bounty._id,
      };
      setTitle('');
      setWallet('');
      setImp('');
      setimpText('');
      setImpact([]);
      setTarget(false);
      setSeverity('Low');
      setImp('smart_contract');
      setTargetNType({});
      setimpText('Smart Contract');
      setSelectedOption(null);
      window.scrollTo(0, 0);
      dispatch(reportRegister(report));
      toast.success('Report Submitted!');
    } else {
      toast.error('Please fill the details!');
    }
  };

  const initialString =
    '<h3>Bug Description</h3><p>A clear and concise description of the bug.</p><h3>Impact</h3><h3>Risk Breakdown</h3><p>Difficulty to Exploit: Easy<br/>Weakness:<br/>CVSS2 Score:</p><h3>Recommendation</h3><h3>References</h3>';

  return (
    <>
      <div className={style.Report}>
        <h1>Submit vulnerability</h1>
        {!loading ? (
          <>
            <div className={style.Assets}>
              <p className={style.Program}>Program</p>
              <div className={style.Program_select}>
                <Select
                  styles={customStyles}
                  defaultValue={selectedOption}
                  onChange={changeInput}
                  options={options}
                />
              </div>
              {showTarget ? (
                <>
                  {bountyByID.success ? (
                    <>
                      <p
                        style={{
                          marginTop: '30px',
                          color: 'rgb(236, 195, 236)',
                        }}
                      >
                        Choose Target
                      </p>
                      <span className={style.sub}>
                        Provide a reference to the code base where the
                        vulnerability was found.
                      </span>
                      {bountyByID.bounty.assets_in_scope.map(
                        (single_assest) => {
                          return (
                            <div key={single_assest._id} className={style.Flex}>
                              <div>
                                <div>{single_assest.target}</div>
                                <div>{single_assest.type}</div>
                              </div>
                              <div>
                                <input
                                  type='radio'
                                  name='target'
                                  onClick={() =>
                                    setTargetnType(
                                      single_assest.target,
                                      single_assest.type
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                      <p
                        style={{
                          marginTop: '30px',
                          color: 'rgb(236, 195, 236)',
                        }}
                      >
                        Impact - {impText}
                      </p>
                      <span className={style.sub}>
                        Choose one or more impacts that apply to your
                        vulnerability.
                      </span>
                      {bountyByID.bounty.impact_in_scope[imp].map((single) => {
                        return (
                          <div className={style.Impact} key={single._id}>
                            <input
                              id={single._id}
                              type='checkbox'
                              value={single.impact}
                              onClick={(e) => handleImpact(e)}
                            />
                            <div>{single.impact}</div>
                          </div>
                        );
                      })}
                      <p
                        style={{
                          marginTop: '30px',
                          color: 'rgb(236, 195, 236)',
                        }}
                      >
                        Severity
                      </p>
                      <span className={style.sub}>
                        Choose the severity you think best represents the
                        vulnerability. If you're unsure what severity your bug
                        is, compare it based on our severity scale.
                      </span>
                      <div className={style.severity}>
                        <select
                          className='minimal'
                          value={severity}
                          onChange={(e) => {
                            e.preventDefault();
                            setSeverity(e.target.value);
                          }}
                        >
                          <option>Informational</option>
                          <option>Low</option>
                          <option>Medium</option>
                          <option>High</option>
                          <option>Critical</option>
                        </select>
                      </div>
                      <p
                        style={{
                          marginTop: '30px',
                          color: 'rgb(236, 195, 236)',
                        }}
                      >
                        Report
                      </p>
                      <span className={style.sub}>
                        Report title, description etc.
                      </span>
                      <p
                        style={{
                          marginTop: '10px',
                          color: 'rgb(236, 195, 236)',
                        }}
                      >
                        Title
                      </p>
                      <div className={style.Report_title}>
                        <input
                          value={title}
                          type='text'
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder='Descriptive Summary...'
                        />
                      </div>
                      <p
                        style={{
                          marginTop: '30px',
                          color: 'rgb(236, 195, 236)',
                        }}
                      >
                        Description
                      </p>
                      <span className={style.sub}>
                        Describe your vulnerability in as much detail as
                        possible. The more detail you provide, the faster we can
                        triage and get you paid.
                      </span>
                      <div style={{ marginTop: '10px' }}>
                        <TextEditor
                          editorRef={descriptionRef}
                          initial={initialString}
                        />
                      </div>
                      <p
                        style={{
                          marginTop: '30px',
                          color: 'rgb(236, 195, 236)',
                        }}
                      >
                        Proof of concept
                      </p>
                      <span className={style.sub}>
                        Some bug types and severity levels requires a PoC.
                        Please provide the proof of concept here if this is the
                        case. See their bounty rules for more information
                      </span>
                      <div style={{ marginTop: '10px' }}>
                        <TextEditor editorRef={pocRef} />
                      </div>
                      <p
                        style={{
                          marginTop: '30px',
                          color: 'rgb(236, 195, 236)',
                        }}
                      >
                        Wallet Address
                      </p>
                      <span className={style.sub}>
                        If a project accepts your bug report, you may be
                        eligible for a reward. Please give us a legitimate
                        Polkadot address where you would like to receive the
                        payment. Be sure to use a wallet you own and not an
                        address provided by an exchange. Payment may be made in
                        the token described on the bounty page.
                      </span>
                      <div className={style.Wallet}>
                        <input
                          value={wallet}
                          onChange={(e) => setWallet(e.target.value)}
                          type='text'
                          placeholder='Address'
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
            <div onClick={(e) => submitHandle(e)} className={style.Button}>
              Submit
            </div>
          </>
        ) : (
          <div className={style.Center}>
            <Oval
              ariaLabel='loading-indicator'
              height={80}
              width={80}
              strokeWidth={5}
              color='white'
              secondaryColor='purple'
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Report;
