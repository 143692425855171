import React from 'react';
import style from './Hacker.module.css';
import source from '../../../Images/source-code.svg';
import ortho from '../../../Images/orthogonal-view.svg';
import { useNavigate } from 'react-router-dom';

const HackerProject = () => {
  const navigate = useNavigate();
  return (
    <div className={style.HackerProject}>
      <div className={style.Left}>
        <img src={source} alt='source' />
        <h1>For Hackers</h1>
        <p>
          Hack DeFi, save users from theft, and get paid doing it. Sign up to
          hackerbro as a hacker.
        </p>
        <span onClick={() => navigate('/signup')}>Sign Up</span>
      </div>
      <div className={style.Line}></div>
      <div className={style.Right}>
        <img src={ortho} alt='ortho' />
        <h1>For Projects</h1>
        <p>
          Secure your project against catastrophic exploits with DeFi's leading
          security talent.
        </p>
        <span>Learn how</span>
      </div>
    </div>
  );
};

export default HackerProject;
