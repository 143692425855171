export const userBountyReducer = (state = {}, action) => {
  switch (action.type) {
    case 'USER_BOUNTY_REQUEST':
      return {
        loading: true,
      };
    case 'USER_BOUNTY_SUCCESS':
      return {
        loading: false,
        success: true,
        bounty: action.payload.user.bounties,
      };
    case 'USER_BOUNTY_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    default:
      return state;
  }
};

export const getAllBountyReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_ALL_BOUNTY_REQUEST':
      return {
        loading: true,
      };
    case 'GET_ALL_BOUNTY_SUCCESS':
      return {
        loading: false,
        success: true,
        bounty: action.payload,
      };
    case 'GET_ALL_BOUNTY_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    default:
      return state;
  }
};

export const addBountyReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_BOUNTY_REQUEST':
      return {
        loading: true,
      };
    case 'ADD_BOUNTY_SUCCESS':
      return {
        loading: false,
        success: true,
      };
    case 'ADD_BOUNTY_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    default:
      return state;
  }
};

export const getBountyByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_BOUNTY_BY_ID_REQUEST':
      return {
        loading: true,
      };
    case 'GET_BOUNTY_BY_ID_SUCCESS':
      return {
        loading: false,
        success: true,
        bounty: action.payload,
      };
    case 'GET_BOUNTY_BY_ID_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    default:
      return state;
  }
};

export const updateBountyReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_BOUNTY_REQUEST':
      return {
        loading: true,
      };
    case 'UPDATE_BOUNTY_SUCCESS':
      return {
        loading: false,
        success: true,
        bounty: action.payload,
      };
    case 'UPDATE_BOUNTY_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    case 'UPDATE_BOUNTY_RESET':
      return {};
    default:
      return state;
  }
};
