import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReportById, updateReport } from '../../actions/report.action';
import style from './Submission.module.css';
import { Oval } from 'react-loader-spinner';
import Footer from '../../Components/Footer/Footer';
import { toast } from 'react-toastify';

const Submission = () => {
  const dispatch = useDispatch();
  const path = window.location.pathname;
  const id = path.split('/')[2];

  const user = JSON.parse(localStorage.getItem('curUser'));
  const [status, setStatus] = useState('');

  useEffect(() => {
    dispatch(getReportById(id));
  }, [dispatch, id]);

  const { report, loading, success } = useSelector((state) => {
    return state.getReportByIdReducer;
  });

  const updatedReportState = useSelector((state) => state.updateReportReducer);
  if (!updatedReportState.loading && updatedReportState.success) {
    toast.success('Update Successful!');
  } else {
    toast.error(updatedReportState.message);
  }

  return !loading && success ? (
    <>
      <div className={style.Submission}>
        <h1>Details</h1>
        {user && user.user.isAdmin ? (
          <select
            value={status === '' ? report.status : status}
            onChange={(e) => {
              setStatus(e.target.value);
              console.log(e.target.value);
              dispatch(updateReport(report._id, e.target.value));
            }}
            style={{ marginTop: '20px' }}
            className='minimal'
          >
            <option>Pending</option>
            <option>Under review</option>
            <option>Approved</option>
          </select>
        ) : null}
        <div className={style.Report}>
          <p>Report ID</p>
          {report._id}
        </div>
        <div className={style.Status}>
          <p>Status</p>
          <div className={style.Status_info}>{report.status}</div>
        </div>
        <div className={style.Severity}>
          <p>Severity</p>
          <div className={style.Severity_info}>{report.severity}</div>
        </div>
        <div className={style.Target}>
          <p>Target</p>
          {report.target.target}
          <div className={style.Target_type}>{report.target.type}</div>
        </div>
        <div className={style.Description}>
          <p>Description</p>
          <div
            dangerouslySetInnerHTML={{
              __html: `${report.report.desc}`,
            }}
          />
        </div>
        {report.report.poc !== '' ? (
          <div className={style.Description}>
            <p>Proof of concept</p>
            <div
              dangerouslySetInnerHTML={{
                __html: `${report.report.poc}`,
              }}
            />
          </div>
        ) : null}
        <div className={style.UPI}>
          <p>Wallet Address</p>
          <div className={style.upi}>{report.upi}</div>
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <div className={style.Center}>
      <Oval
        ariaLabel='loading-indicator'
        height={80}
        width={80}
        strokeWidth={5}
        color='white'
        secondaryColor='purple'
      />
    </div>
  );
};

export default Submission;
