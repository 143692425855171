import style from './Explore.module.css';
import { useSelector } from 'react-redux';
import { Oval } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import Footer from '../../Components/Footer/Footer';
import BountyTile from '../../Components/BountyTile/BountyTile';
import NewsLetter from '../../Components/NewsLetter/NewsLetter';

const Explore = () => {
  const { bounty, loading, success } = useSelector(
    (state) => state.getAllBountyReducer
  );

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className={style.Explore}>
        <div className={style.Explore_Heading}>
          <h1>Explore Bounties</h1>
          <div className={style.Explore_Flex}>
            <div className={style.Explore_All}>All Bounties</div>
            <div className={style.Explore_Select}>
              <select className='minimal'>
                <option value=''>Newest to oldest</option>
                <option value=''>Oldest to newest</option>
                <option value=''>$High to $low</option>
                <option value=''>$Low to $high</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <div className={style.Explore}>
        <div style={{ marginTop: '100px' }}>
          {!loading && success ? (
            bounty.map((b) => {
              return <BountyTile key={b._id} bounty={b} />;
            })
          ) : (
            <div className={style.Center}>
              <Oval
                ariaLabel='loading-indicator'
                height={80}
                width={80}
                strokeWidth={5}
                color='white'
                secondaryColor='purple'
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Explore;
