import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allReport } from '../../../actions/report.action';
import { Oval } from 'react-loader-spinner';
import style from './Reports.module.css';
import Tile from '../../Dashboard/Tile/Tile';
import { useNavigate } from 'react-router-dom';

const Reports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allReports = useSelector((state) => state.getAllReportReducer);

  const { loading, success, reports } = allReports;

  const handleUser = (id) => {
    navigate(`/admin/user/${id}`);
  };

  useEffect(() => {
    dispatch(allReport());
  }, [dispatch]);

  return (
    <>
      <h1 style={{ color: 'rgb(206, 144, 206)' }}>Reports</h1>
      <div className={style.Reports}>
        {!loading && success ? (
          reports.length !== 0 ? (
            reports
              .slice(0)
              .reverse()
              .map((r) => {
                return (
                  <>
                    <h3
                      className={style.UserClick}
                      onClick={() => handleUser(r.user)}
                    >
                      User : {r.user}
                    </h3>
                    <Tile report={r} key={r._id} />
                  </>
                );
              })
          ) : (
            <div className={style.Center} style={{ fontWeight: '500' }}>
              No report found!
            </div>
          )
        ) : (
          <div className={style.Center}>
            <Oval
              ariaLabel='loading-indicator'
              height={80}
              width={80}
              strokeWidth={5}
              color='white'
              secondaryColor='purple'
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Reports;
