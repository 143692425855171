import React from 'react';
import BountyTile from '../../../Components/BountyTile/BountyTile';
import style from './Bounty.module.css';
import { useSelector } from 'react-redux';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

const Bounty = () => {
  const { bounty, loading, success } = useSelector(
    (state) => state.getAllBountyReducer
  );
  return (
    <div className={style.Bounty}>
      {!loading && success ? (
        bounty.map((b) => {
          return <BountyTile key={b._id} bounty={b} />;
        })
      ) : (
        <div className={style.Center}>
          <Oval
            ariaLabel='loading-indicator'
            height={80}
            width={80}
            strokeWidth={5}
            color='white'
            secondaryColor='purple'
          />
        </div>
      )}
      <div style={{ marginTop: '50px' }}>
        <Link className={`${style.Button} link`} to='/explore'>
          Explore all bounties
        </Link>
      </div>
    </div>
  );
};

export default Bounty;
