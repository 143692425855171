import Tile from './Tile/Tile';
import { Link } from 'react-router-dom';
import style from './Dashboard.module.css';
import { Oval } from 'react-loader-spinner';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userReport } from '../../actions/report.action';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('All');
  const [severity, setSeverity] = useState('Low');
  const curUser = JSON.parse(localStorage.getItem('curUser'));

  const { rep, loading, success } = useSelector((state) => {
    return state.userReportReducer;
  });

  useEffect(() => {
    dispatch(userReport(curUser.user._id));
  }, [dispatch, curUser.user._id]);

  return (
    <div className={style.Dashboard}>
      <div className={style.Tile}>
        <div className={style.Heading}>
          <p>My Submission</p>
          <p>These are reports that you have personally submitted.</p>
        </div>
        <div className={style.Selection}>
          <div>
            <p>Status</p>
            <select
              className='minimal'
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              disabled={
                !loading && success ? (rep.length === 0 ? true : false) : null
              }
            >
              <option>All</option>
              <option>Pending</option>
              <option>Completed</option>
            </select>
          </div>
          <div>
            <p>Severity</p>
            <select
              className='minimal'
              value={severity}
              onChange={(e) => setSeverity(e.target.value)}
              disabled={
                !loading && success ? (rep.length === 0 ? true : false) : null
              }
            >
              <option>Low</option>
              <option>High</option>
              <option>Critical</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        {!loading && success ? (
          rep.length !== 0 ? (
            rep.map((r) => {
              return <Tile report={r} key={r._id} />;
            })
          ) : (
            <div className={style.Center} style={{ fontWeight: '500' }}>
              No report found!
            </div>
          )
        ) : (
          <div className={style.Center}>
            <Oval
              ariaLabel='loading-indicator'
              height={80}
              width={80}
              strokeWidth={5}
              color='white'
              secondaryColor='purple'
            />
          </div>
        )}
      </div>
      <Link to='/new-submission'>
        <div className={style.Report}>
          <i className='fas fa-plus-square fa-3x'></i>
        </div>
      </Link>
    </div>
  );
};

export default Dashboard;
