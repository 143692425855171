import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = ({ editorRef, initial }) => {
  // const editorRef = useRef(null);
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };
  return (
    <>
      <Editor
        apiKey='qgyrfy00g07swy2vncmdjr0wru8c4gwjr7avj28t0gb90a8l'
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initial}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
};

export default TextEditor;
