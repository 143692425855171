import React, { useState } from 'react';
import style from './Register.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { userRegister } from '../../actions/user.action';
import { ValidateEmail } from '../../utils/ValidateEmail';

const Register = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const registerState = useSelector((state) => state.userRegisterReducer);
  const { loading, success, message } = registerState;
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    const user = { name, email, password };
    if (name && email && password) {
      if (ValidateEmail(email)) dispatch(userRegister(user));
    } else {
      toast.error('Please fill the details!');
    }
  };

  if (!loading && success) {
    toast.success('User registered!');
    navigate('/login');
  } else {
    toast.error(message);
  }

  return (
    <div className={style.Register}>
      <p>Start submitting bugs</p>
      <div className={style.details}>
        <p>Enter your details below to create an account.</p>
      </div>
      <div className={style.section}>
        <p>Username</p>
        <input
          type='text'
          value={name}
          placeholder='hunter123'
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className={style.section}>
        <p>Email</p>
        <input
          type='email'
          value={email}
          placeholder='hunter@hackerbro.in'
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className={style.section}>
        <p>Password</p>
        <input
          type='password'
          value={password}
          placeholder='Enter your password'
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className={style.Button} onClick={submitHandler}>
        Sign-up
      </div>
      <div className={style.Bottom}>
        <span>Already have an account?</span>
        <Link
          to='/login'
          className='link'
          style={{ color: 'rgb(161, 35, 161)' }}
        >
          {' '}
          Login here.
        </Link>
      </div>
    </div>
  );
};

export default Register;
