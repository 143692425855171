import React from 'react';
import { useSelector } from 'react-redux';
import style from './Bounties.module.css';
import { Oval } from 'react-loader-spinner';
import BountyTile from '../../../Components/BountyTile/BountyTile';

const Bounties = () => {
  const user = JSON.parse(localStorage.getItem('curUser'));
  const { bounty, loading, success } = useSelector(
    (state) => state.getAllBountyReducer
  );

  return (
    <>
      <h1 style={{ color: 'rgb(206, 144, 206)' }}>Bounties</h1>
      <div className={style.Bounties}>
        {!loading && success ? (
          bounty.length !== 0 ? (
            bounty
              .slice(0)
              .reverse()
              .map((b) => {
                return (
                  <BountyTile
                    key={b._id}
                    bounty={b}
                    admin={user.user.isAdmin}
                  />
                );
              })
          ) : (
            <div className={style.Center} style={{ fontWeight: '500' }}>
              No bounty found!
            </div>
          )
        ) : (
          <div className={style.Center}>
            <Oval
              ariaLabel='loading-indicator'
              height={80}
              width={80}
              strokeWidth={5}
              color='white'
              secondaryColor='purple'
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Bounties;
