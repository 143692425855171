import React from 'react';
import style from './Footer.module.css';
import NewsLetter from '../NewsLetter/NewsLetter';
import Social from './Social/Social';

const Footer = () => {
  return (
    <div className={style.SectionColor}>
      <div className={style.Footer}>
        <div className={style.Logo}>
          <div>HackerBro</div>
        </div>
        <div className={style.Section}>
          <ul>
            <li>Explore</li>
            <li>Hackers</li>
            <li>Projects</li>
            <li>Priority One</li>
            <li>Nexus Matching</li>
            <li>White Scholarship</li>
          </ul>
        </div>
        <div className={style.Section}>
          <ul>
            <li>About</li>
            <li>Rules</li>
            <li>Press</li>
            <li>Brand Assests</li>
            <li>Crypto Losses Report</li>
            <li>White Hall of Fame</li>
          </ul>
        </div>
        <div className={style.Section}>
          <ul>
            <li>Blog</li>
            <li>Contact</li>
            <li>Help</li>
            <li>Privacy</li>
            <li>Careers</li>
            <li>Employee Verification</li>
          </ul>
        </div>
      </div>
      <NewsLetter />
      <Social />
    </div>
  );
};

export default Footer;
