import React from 'react';
import style from './Tile.module.css';
import { useNavigate } from 'react-router-dom';

const Tile = ({ report }) => {
  const navigate = useNavigate();

  const handleReport = (report) => {
    navigate(`/submission/${report._id}`);
  };

  return (
    <div onClick={() => handleReport(report)} className={style.Tile}>
      <h2>{report.report.title}</h2>
      <p>{`Submited for ${report.program} program`}</p>
      <div className={style.Lower}>
        <p>{report.severity}</p>
        <p>{report.status}</p>
      </div>
      <div className={style.Line}></div>
    </div>
  );
};

export default Tile;
