import { toast } from 'react-toastify';
import style from './Addbounty.module.css';
import React, { useState, useRef } from 'react';
import Footer from '../../Components/Footer/Footer';
import { useDispatch } from 'react-redux/es/exports';
import TextEditor from '../../Components/Editor/TextEditor';
import { bountyRegister } from '../../actions/bounty.action';

const Addbounty = () => {
  const dispatch = useDispatch();
  const rewardsRef = useRef(null);
  const outOfScopeRef = useRef(null);
  const [name, setName] = useState('');
  const programOverviewRef = useRef(null);
  const user = JSON.parse(localStorage.getItem('curUser'));
  const [smart_contract, set_smart_contract] = useState([
    { level: 'Low', payout: '', poc: 'Yes' },
  ]);
  const [web_and_application, set_web_and_application] = useState([
    { level: 'Low', payout: '', poc: 'Yes' },
  ]);
  const [blockchain, set_blockchain] = useState([
    { level: 'Low', payout: '', poc: 'Yes' },
  ]);
  const [assets_in_scope, set_assets_in_scope] = useState([
    { target: '', type: '' },
  ]);
  const [ismart_contract, set_ismart_contract] = useState([
    { impact: '', level: 'Low' },
  ]);
  const [iweb_and_application, set_iweb_and_application] = useState([
    { impact: '', level: 'Low' },
  ]);
  const [iblockchain, set_iblockchain] = useState([
    { impact: '', level: 'Low' },
  ]);

  //Smarrt Contract
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const list = [...smart_contract];
    list[i][name] = value;
    set_smart_contract(list);
  };

  const handle_smart_contract_Remove = (index) => {
    const list = [...smart_contract];
    list.splice(index, 1);
    set_smart_contract(list);
  };

  const handle_smart_contract_add = () => {
    set_smart_contract([
      ...smart_contract,
      { level: 'Easy', payout: '', poc: 'Yes' },
    ]);
  };

  //Web and application
  const handleChangeWA = (e, i) => {
    const { name, value } = e.target;
    const list = [...web_and_application];
    list[i][name] = value;
    set_web_and_application(list);
  };

  const handle_web_and_application_Remove = (index) => {
    const list = [...web_and_application];
    list.splice(index, 1);
    set_web_and_application(list);
  };

  const handle_web_and_application_add = () => {
    set_web_and_application([
      ...web_and_application,
      { level: 'Easy', payout: '', poc: 'Yes' },
    ]);
  };

  const handle_change_blockchange = (e, i) => {
    const { name, value } = e.target;
    const list = [...blockchain];
    list[i][name] = value;
    set_blockchain(list);
  };

  const handle_blockchain_Remove = (index) => {
    const list = [...blockchain];
    list.splice(index, 1);
    set_blockchain(list);
  };

  const handle_blockchain_add = () => {
    set_blockchain([...blockchain, { level: 'Easy', payout: '', poc: 'Yes' }]);
  };

  //Assets in scope
  const handle_assets_in_scope_change = (e, i) => {
    const { name, value } = e.target;
    const list = [...assets_in_scope];
    list[i][name] = value;
    set_assets_in_scope(list);
  };

  const handle_assets_in_scope_remove = (index) => {
    const list = [...assets_in_scope];
    list.splice(index, 1);
    set_assets_in_scope(list);
  };

  const handle_assets_in_scope_add = () => {
    set_assets_in_scope([...assets_in_scope, { target: '', type: '' }]);
    console.log(assets_in_scope);
  };

  //Ismart_contract
  const handle_ismart_contract_change = (e, i) => {
    const { name, value } = e.target;
    const list = [...ismart_contract];
    list[i][name] = value;
    set_ismart_contract(list);
  };

  const handle_ismart_contract_remove = (index) => {
    const list = [...ismart_contract];
    list.splice(index, 1);
    set_ismart_contract(list);
  };

  const handle_ismart_contract_add = () => {
    set_ismart_contract([...ismart_contract, { impact: '', level: 'Easy' }]);
    console.log(ismart_contract);
  };

  //IWeb_and_application
  const handle_iweb_and_application_change = (e, i) => {
    const { name, value } = e.target;
    const list = [...iweb_and_application];
    list[i][name] = value;
    set_iweb_and_application(list);
  };

  const handle_iweb_and_application_remove = (index) => {
    const list = [...iweb_and_application];
    list.splice(index, 1);
    set_iweb_and_application(list);
  };

  const handle_iweb_and_application_add = () => {
    set_iweb_and_application([
      ...iweb_and_application,
      { impact: '', level: 'Easy' },
    ]);
    console.log(iweb_and_application);
  };

  //IBlockchain
  const handle_iblockchain_change = (e, i) => {
    const { name, value } = e.target;
    const list = [...iblockchain];
    list[i][name] = value;
    set_iblockchain(list);
  };

  const handle_iblockchain_remove = (index) => {
    const list = [...iblockchain];
    list.splice(index, 1);
    set_iblockchain(list);
  };

  const handle_iblockchain_add = () => {
    set_iblockchain([...iblockchain, { impact: '', level: 'Easy' }]);
    console.log(iblockchain);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      name &&
      programOverviewRef.current.getContent() &&
      rewardsRef.current.getContent() &&
      smart_contract &&
      web_and_application &&
      blockchain &&
      assets_in_scope &&
      ismart_contract &&
      iweb_and_application &&
      iblockchain &&
      outOfScopeRef.current.getContent()
    ) {
      const data = {
        name: name,
        program_overview: programOverviewRef.current.getContent(),
        rewards_by_threat_level: rewardsRef.current.getContent(),
        smart_contract: smart_contract,
        web_and_application: web_and_application,
        blockchain: blockchain,
        assets_in_scope: assets_in_scope,
        impact_in_scope: {
          smart_contract: ismart_contract,
          web_and_application: iweb_and_application,
          blockchain: iblockchain,
        },
        out_of_scope: outOfScopeRef.current.getContent(),
        user: user.user._id,
      };
      console.log(data);
      window.scrollTo(0, 0);
      toast.success('Bounty Program Submitted!');
      dispatch(bountyRegister(data));
    } else {
      toast.error('Please fill the details!');
    }
  };

  return (
    <>
      <div className={style.Addbounty}>
        <h1>Add bug bounty program</h1>
        <div className={style.section}>
          <p className={style.Label}>Name</p>
          <input
            onChange={(e) => setName(e.target.value)}
            type='text'
            value={name}
            placeholder='Program name'
            style={{ width: '100%' }}
          />
        </div>
        <div className={style.section}>
          <p className={style.Label}>Program Overview</p>
          <TextEditor editorRef={programOverviewRef} />
        </div>
        <div className={style.section}>
          <p className={style.Label}>Rewards by threat level</p>
          <TextEditor editorRef={rewardsRef} />
        </div>
        <div className={style.section}>
          <p className={style.Label}>Smart Contract</p>
          {smart_contract.map((sM, i) => {
            return (
              <div key={i}>
                <div className={style.Flex}>
                  <div>
                    <p>Level</p>
                    <select
                      name='level'
                      className='minimal'
                      onChange={(e) => handleChange(e, i)}
                    >
                      <option>Low</option>
                      <option>Medium</option>
                      <option>High</option>
                      <option>Critical</option>
                    </select>
                  </div>
                  <div>
                    <p>Payout</p>
                    <input
                      name='payout'
                      onChange={(e) => handleChange(e, i)}
                      type='text'
                      value={smart_contract[i].payout}
                      placeholder='payout'
                    />
                  </div>
                  <div>
                    <p>POC</p>
                    <select
                      name='poc'
                      className='minimal'
                      value={smart_contract[i].poc}
                      onChange={(e) => handleChange(e, i)}
                    >
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                  {smart_contract.length > 1 && (
                    <div
                      className={style.Delete}
                      onClick={() => handle_smart_contract_Remove(i)}
                    >
                      <p>Delete</p>
                      <i
                        style={{ cursor: 'pointer' }}
                        className='far fa-trash-alt fa-2x'
                      ></i>
                    </div>
                  )}
                </div>
                {smart_contract.length - 1 === i && (
                  <div
                    className={style.Icon}
                    onClick={handle_smart_contract_add}
                  >
                    <i
                      style={{ cursor: 'pointer' }}
                      className='far fa-plus-square fa-2x'
                    ></i>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={style.section}>
          <p className={style.Label}>Web and Applications</p>
          {web_and_application.map((wA, i) => {
            return (
              <div key={i}>
                <div className={style.Flex}>
                  <div>
                    <p>Level</p>
                    <select
                      className='minimal'
                      name='level'
                      onChange={(e) => handleChangeWA(e, i)}
                    >
                      <option>Low</option>
                      <option>Medium</option>
                      <option>High</option>
                      <option>Critical</option>
                    </select>
                  </div>
                  <div>
                    <p>Payout</p>
                    <input
                      name='payout'
                      onChange={(e) => handleChangeWA(e, i)}
                      type='text'
                      value={web_and_application[i].payout}
                      placeholder='payout'
                    />
                  </div>
                  <div>
                    <p>POC</p>
                    <select
                      name='poc'
                      className='minimal'
                      value={web_and_application[i].poc}
                      onChange={(e) => handleChangeWA(e, i)}
                    >
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                  {web_and_application.length > 1 && (
                    <div
                      className={style.Delete}
                      onClick={() => handle_web_and_application_Remove(i)}
                    >
                      <p>Delete</p>
                      <i
                        style={{ cursor: 'pointer' }}
                        className='far fa-trash-alt fa-2x'
                      ></i>
                    </div>
                  )}
                </div>
                {web_and_application.length - 1 === i && (
                  <div
                    className={style.Icon}
                    onClick={handle_web_and_application_add}
                  >
                    <i
                      style={{ cursor: 'pointer' }}
                      className='far fa-plus-square fa-2x'
                    ></i>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={style.section}>
          <p className={style.Label}>Blockchain</p>
          {blockchain.map((wA, i) => {
            return (
              <div key={i}>
                <div className={style.Flex}>
                  <div>
                    <p>Level</p>
                    <select
                      className='minimal'
                      name='level'
                      onChange={(e) => handle_change_blockchange(e, i)}
                    >
                      <option>Low</option>
                      <option>Medium</option>
                      <option>High</option>
                      <option>Critical</option>
                    </select>
                  </div>
                  <div>
                    <p>Payout</p>
                    <input
                      name='payout'
                      onChange={(e) => handle_change_blockchange(e, i)}
                      type='text'
                      value={blockchain[i].payout}
                      placeholder='payout'
                    />
                  </div>
                  <div>
                    <p>POC</p>
                    <select
                      className='minimal'
                      name='poc'
                      value={blockchain[i].poc}
                      onChange={(e) => handle_change_blockchange(e, i)}
                    >
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                  {blockchain.length > 1 && (
                    <div
                      className={style.Delete}
                      onClick={() => handle_blockchain_Remove(i)}
                    >
                      <p>Delete</p>
                      <i
                        style={{ cursor: 'pointer' }}
                        className='far fa-trash-alt fa-2x'
                      ></i>
                    </div>
                  )}
                </div>
                {blockchain.length - 1 === i && (
                  <div className={style.Icon} onClick={handle_blockchain_add}>
                    <i
                      style={{ cursor: 'pointer' }}
                      className='far fa-plus-square fa-2x'
                    ></i>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={style.section}>
          <p className={style.Label}>Assests in scope</p>
          {assets_in_scope.map((b, i) => {
            return (
              <div key={i}>
                <div className={style.Flex}>
                  <div className={style.FlexInside}>
                    <p>Target</p>
                    <input
                      name='target'
                      onChange={(e) => handle_assets_in_scope_change(e, i)}
                      type='text'
                      placeholder='Target'
                      value={assets_in_scope[i].target}
                    />
                  </div>
                  <div className={style.FlexInside}>
                    <p>Type</p>
                    <input
                      name='type'
                      onChange={(e) => handle_assets_in_scope_change(e, i)}
                      type='text'
                      placeholder='Type'
                      value={assets_in_scope[i].type}
                    />
                  </div>
                  {assets_in_scope.length > 1 && (
                    <div onClick={() => handle_assets_in_scope_remove(i)}>
                      <p>Delete</p>
                      <i
                        style={{ cursor: 'pointer' }}
                        className='far fa-trash-alt fa-2x'
                      ></i>
                    </div>
                  )}
                </div>
                {assets_in_scope.length - 1 === i && (
                  <div
                    onClick={handle_assets_in_scope_add}
                    className={style.Icon}
                  >
                    <i
                      style={{ cursor: 'pointer' }}
                      className='far fa-plus-square fa-2x'
                    ></i>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={style.section}>
          <p className={style.Label}>Impact in scope</p>
          <div>
            <p style={{ marginDown: '20px !important' }}>Smart Contract</p>
            {ismart_contract.map((is, i) => {
              return (
                <div key={i}>
                  <div className={style.Flex}>
                    <div className={style.FlexInside}>
                      <p>Impact</p>
                      <input
                        name='impact'
                        onChange={(e) => handle_ismart_contract_change(e, i)}
                        type='text'
                        placeholder='Target'
                        value={ismart_contract[i].impact}
                      />
                    </div>
                    <div className={style.FlexInside}>
                      <p>Level</p>
                      <select
                        className='minimal'
                        name='level'
                        value={ismart_contract[i].level}
                        onChange={(e) => handle_ismart_contract_change(e, i)}
                      >
                        <option>Low</option>
                        <option>Medium</option>
                        <option>High</option>
                        <option>Critical</option>
                      </select>
                    </div>
                    {ismart_contract.length > 1 && (
                      <div
                        className={style.Delete}
                        onClick={() => handle_ismart_contract_remove(i)}
                      >
                        <p>Delete</p>
                        <i
                          style={{ cursor: 'pointer' }}
                          className='far fa-trash-alt fa-2x'
                        ></i>
                      </div>
                    )}
                  </div>
                  {ismart_contract.length - 1 === i && (
                    <div
                      onClick={handle_ismart_contract_add}
                      className={style.Icon}
                    >
                      <i
                        style={{ cursor: 'pointer' }}
                        className='far fa-plus-square fa-2x'
                      ></i>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: '20px' }}>
            <p>Web and Applications</p>
            {iweb_and_application.map((is, i) => {
              return (
                <div key={i}>
                  <div className={style.Flex}>
                    <div className={style.FlexInside}>
                      <p>Impact</p>
                      <input
                        name='impact'
                        onChange={(e) =>
                          handle_iweb_and_application_change(e, i)
                        }
                        type='text'
                        placeholder='Target'
                        value={iweb_and_application[i].impact}
                      />
                    </div>
                    <div className={style.FlexInside}>
                      <p>Level</p>
                      <select
                        name='level'
                        className='minimal'
                        value={iweb_and_application[i].level}
                        onChange={(e) =>
                          handle_iweb_and_application_change(e, i)
                        }
                      >
                        <option>Low</option>
                        <option>Medium</option>
                        <option>High</option>
                        <option>Critical</option>
                      </select>
                    </div>
                    {iweb_and_application.length > 1 && (
                      <div
                        className={style.Delete}
                        onClick={() => handle_iweb_and_application_remove(i)}
                      >
                        <p>Delete</p>
                        <i
                          style={{ cursor: 'pointer' }}
                          className='far fa-trash-alt fa-2x'
                        ></i>
                      </div>
                    )}
                  </div>
                  {iweb_and_application.length - 1 === i && (
                    <div
                      onClick={handle_iweb_and_application_add}
                      className={style.Icon}
                    >
                      <i
                        style={{ cursor: 'pointer' }}
                        className='far fa-plus-square fa-2x'
                      ></i>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: '20px' }}>
            <p>BlockChain</p>
            {iblockchain.map((is, i) => {
              return (
                <div key={i}>
                  <div className={style.Flex}>
                    <div className={style.FlexInside}>
                      <p>Impact</p>
                      <input
                        name='impact'
                        onChange={(e) => handle_iblockchain_change(e, i)}
                        type='text'
                        placeholder='Target'
                        value={iblockchain[i].impact}
                      />
                    </div>
                    <div className={style.FlexInside}>
                      <p>Level</p>
                      <select
                        className='minimal'
                        name='level'
                        value={iblockchain[i].level}
                        onChange={(e) => handle_iblockchain_change(e, i)}
                      >
                        <option>Low</option>
                        <option>Medium</option>
                        <option>High</option>
                        <option>Critical</option>
                      </select>
                    </div>
                    {iblockchain.length > 1 && (
                      <div
                        className={style.Delete}
                        onClick={() => handle_iblockchain_remove(i)}
                      >
                        <p>Delete</p>
                        <i
                          style={{ cursor: 'pointer' }}
                          className='far fa-trash-alt fa-2x'
                        ></i>
                      </div>
                    )}
                  </div>
                  {iblockchain.length - 1 === i && (
                    <div
                      onClick={handle_iblockchain_add}
                      className={style.Icon}
                    >
                      <i
                        style={{ cursor: 'pointer' }}
                        className='far fa-plus-square fa-2x'
                      ></i>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={style.section}>
          <p className={style.Label}>Out of scope</p>
          <TextEditor editorRef={outOfScopeRef} />
        </div>
        <div className={style.Button} onClick={(e) => submitHandler(e)}>
          Submit
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Addbounty;
