import React from 'react';
import Users from './Users/Users';
import style from './Admin.module.css';
import { Route, Link, Routes } from 'react-router-dom';
import Bounties from './Bounties/Bounties';
import Reports from './Reports/Reports';
import LoginHistory from './LoginHistory/LoginHistory';
import UserFullView from './Users/UserFullView/UserFullView';

const Admin = () => {
  return (
    <div className={style.Admin}>
      <div className={style.AdminMenu}>
        <Link to='/admin' className='link'>
          <p className={style.Link}>Users</p>
        </Link>
        <Link to='/admin/bounties' className='link'>
          <p className={style.Link}>Bounties</p>
        </Link>
        <Link to='/admin/reports' className='link'>
          <p className={style.Link}>Reports</p>
        </Link>
        <Link to='/admin/loginhistory' className='link'>
          <p className={style.Link}>Login History</p>
        </Link>
      </div>
      <div>
        <Routes>
          <Route path='/' exact element={<Users />} />
          <Route path='/bounties' exact element={<Bounties />} />
          <Route path='/reports' exact element={<Reports />} />
          <Route path='/loginhistory' exact element={<LoginHistory />} />
          <Route path='/user/:id' exact element={<UserFullView />} />
        </Routes>
      </div>
    </div>
  );
};

export default Admin;
