import React from 'react';
import style from './Navbar.module.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../actions/user.action';

const Navbar = () => {
  const dispatch = useDispatch();

  const curUser = JSON.parse(localStorage.getItem('curUser'));

  const ClickHandle = (e) => {
    e.preventDefault();
    dispatch(userLogout());
  };

  return (
    <div className={style.Navbar}>
      <div className={style.Logo}>
        <Link to='/' className={`${style.Logo} link`}>
          HackerBro
        </Link>
      </div>
      <div>
        {!curUser ? (
          <Link to='/howitworks' className={`${style.List} link`}>
            How it Works
          </Link>
        ) : (
          <span className={style.UserName}>
            Welcome, {curUser.user.name.split(' ')[0]}
          </span>
        )}
        {!curUser ? (
          <Link to='/learn' className={`${style.List} link`}>
            Learn
          </Link>
        ) : (
          <Link to='/dashboard' className={`${style.List} link`}>
            Submission
          </Link>
        )}
        {!curUser ? null : (
          <Link to='/bounty' className={`${style.List} link`}>
            Bounty
          </Link>
        )}
        {!curUser ? (
          <a
            href='https://discord.com/'
            target='self'
            className={`${style.List} link`}
          >
            Community
          </a>
        ) : (
          <Link to='/setting' className={`${style.List} link`}>
            Settings
          </Link>
        )}

        {!curUser ? (
          <Link to='/login' className={`${style.List} link`}>
            Login
          </Link>
        ) : (
          <span className={style.List} onClick={ClickHandle}>
            Logout
          </span>
        )}
        <Link to='/explore' className={`${style.Button} ${style.List} link`}>
          Explore Bounties
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
