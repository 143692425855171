import React, { useEffect } from 'react';
import style from './MyBounty.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { userBounty } from '../../actions/bounty.action';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';

const MyBounty = () => {
  const dispatch = useDispatch();
  const curUser = JSON.parse(localStorage.getItem('curUser'));
  const navigate = useNavigate();
  const { bounty, loading, success } = useSelector((state) => {
    return state.userBountyReducer;
  });

  useEffect(() => {
    dispatch(userBounty(curUser.user._id));
  }, [dispatch, curUser.user._id]);

  const handleBounty = (id) => {
    navigate(`/bounty/${id}`);
  };

  return (
    <div className={style.MyBounty}>
      <div className={style.Tile}>
        <div className={style.Heading}>
          <p>My Bounties</p>
          <p>These are bounties that you have personally submitted.</p>
        </div>
      </div>
      {!loading && success ? (
        bounty.length !== 0 ? (
          bounty.map((b) => {
            const time = b.createdAt.split('T')[0];
            return (
              <div
                onClick={() => handleBounty(b._id)}
                className={style.bountyTile}
                key={b._id}
              >
                <h2>{b.name}</h2>
                <p>Created At: {time}</p>
                <p>Status: {b.status}</p>
                <div className={style.Line}></div>
              </div>
            );
          })
        ) : (
          <div className={style.Center} style={{ fontWeight: '500' }}>
            No bug bounty program found!
          </div>
        )
      ) : (
        <div className={style.Center}>
          <Oval
            ariaLabel='loading-indicator'
            height={80}
            width={80}
            strokeWidth={5}
            color='white'
            secondaryColor='purple'
          />
        </div>
      )}
      <Link to='/addbounty'>
        <div className={style.AddBounty}>
          <i className='fas fa-plus-square fa-3x'></i>
        </div>
      </Link>
    </div>
  );
};

export default MyBounty;
