import React, { useEffect } from 'react';
import style from './LoginHistory.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLoginHistory } from '../../../actions/loginhistory.action';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const LoginHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allLoginHistory = useSelector(
    (state) => state.getAllLoginHistoryReducer
  );

  const { data, success, loading } = allLoginHistory;

  useEffect(() => {
    dispatch(getAllLoginHistory());
  }, [dispatch]);

  const handleUser = (id) => {
    navigate(`/admin/user/${id}`);
  };

  return (
    <>
      <h1 style={{ color: 'rgb(206, 144, 206)' }}>Login History</h1>
      <div className={style.LoginHistory}>
        {!loading && success ? (
          data.length !== 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>IP</th>
                  <th>Location</th>
                  <th>Browser</th>
                  <th>OS</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .slice(0)
                  .reverse()
                  .map((his) => {
                    let date = new Date(his.createdAt).toString();
                    const result = date.split(' ');
                    const newDate = `${result[2]}/${result[1]}/${result[3]}`;
                    const newTime = result[4];

                    return (
                      <tr key={his._id}>
                        <td>
                          <div className={style.Date}>{newDate}</div>
                          <div
                            className={style.Time}
                          >{`Time - ${newTime}`}</div>
                        </td>
                        <td
                          className={style.UserClick}
                          onClick={() => handleUser(his.user)}
                        >
                          {his.user}
                        </td>
                        <td>{his.loginHistory.ip}</td>
                        <td>{`${his.loginHistory.location}-${his.loginHistory.country}`}</td>
                        <td>{his.loginHistory.browserName}</td>
                        <td>{his.loginHistory.os}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div className={style.Center} style={{ fontWeight: '500' }}>
              No login history found!
            </div>
          )
        ) : (
          <div className={style.Center}>
            <Oval
              ariaLabel='loading-indicator'
              height={80}
              width={80}
              strokeWidth={5}
              color='white'
              secondaryColor='purple'
            />
          </div>
        )}
      </div>{' '}
    </>
  );
};

export default LoginHistory;
