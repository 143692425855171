import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserById,
  updateLoginUser,
  updateUser,
} from '../../../../actions/user.action';
import { Oval } from 'react-loader-spinner';
import style from './UserFullView.module.css';
import { toast } from 'react-toastify';
import { ValidateEmail } from '../../../../utils/ValidateEmail';

const UserFullView = () => {
  const dispatch = useDispatch();
  const path = window.location.pathname;
  const id = path.split('/')[3];
  const curUser = JSON.parse(localStorage.getItem('curUser'));
  const userState = useSelector((state) => state.getUserByIdReducer);
  const { loading, success, user } = userState;
  const updateUserState = useSelector((state) => state.updateUserReducer);
  const updateLoginUserState = useSelector(
    (state) => state.updateLoginUserReducer
  );

  const [name, setName] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [admin, setAdmin] = useState(null);

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      name,
      pass: password,
      email,
      isAdmin: admin === null ? null : admin ? true : false,
    };
    if ((name && name.length < 2) || name === '') {
      return toast.error('Minimum length 2!');
    }
    if ((password && password.length < 6) || password === '') {
      return toast.error('Password min length 6!');
    }
    if (email === '') {
      return toast.error('Please provide email!');
    }
    if (email && !ValidateEmail(email)) {
      return;
    }
    if (user._id === curUser.user._id) {
      dispatch(updateLoginUser(id, data));
    } else {
      dispatch(updateUser(id, data));
    }
  };

  if (updateUserState.success && !updateUserState.loading) {
    toast.success(updateUserState.message);
  } else {
    toast.error(updateUserState.message);
  }

  if (!updateLoginUserState.loading && updateLoginUserState.success) {
    toast.success(updateLoginUserState.message);
  } else {
    toast.error(updateLoginUserState.message);
  }

  useEffect(() => {
    dispatch(getUserById(id));
  }, [dispatch, id]);

  return (
    <div className={style.UserFullView}>
      {!loading && success ? (
        <>
          <div className={style.Name}>
            <input
              value={name !== null ? name : user.name}
              type='text'
              onChange={(e) => setName(e.target.value)}
              placeholder='Name'
            />
          </div>
          <div className={style.Password}>
            <input
              value={password !== null ? password : user.password}
              type='password'
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
            />
          </div>
          <div className={style.Email}>
            <input
              value={email !== null ? email : user.email}
              type='text'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
            />
          </div>
          <div className={style.Bounties}>
            Total Bounties : {user.bounties.length}
          </div>
          <div className={style.Reports}>
            Total Reports : {user.reports.length}
          </div>
          <div className={style.LoginHistory}>
            Total Login : {user.loginHistory.length}
          </div>
          <div className={style.Admin}>
            <span>Admin </span>
            <select
              className='minimal'
              onChange={(e) => setAdmin(e.target.value)}
              value={admin !== null ? admin : user.isAdmin ? 'Yes' : 'No'}
            >
              <option>No</option>
              <option>Yes</option>
            </select>
          </div>
          <div className={style.ButtonWrap}>
            <div onClick={handleSave} className={style.Button}>
              Save
            </div>
          </div>
        </>
      ) : (
        <div className={style.Center}>
          <Oval
            ariaLabel='loading-indicator'
            height={80}
            width={80}
            strokeWidth={5}
            color='white'
            secondaryColor='purple'
          />
        </div>
      )}
    </div>
  );
};

export default UserFullView;
