import React from 'react';
import style from './Social.module.css';

const Social = () => {
  return (
    <div className={style.Container}>
      <div className={style.Social}>
        <div className={style.Wrapper}>
          <div className={style.Icon}>
            <i className='fab fa-twitter'></i>
          </div>
          <span>Twitter</span>
        </div>
        <div className={style.Wrapper}>
          <div className={style.Icon}>
            <i className='fab fa-medium'></i>
          </div>
          <span>Medium</span>
        </div>
        <div className={style.Wrapper}>
          <div className={style.Icon}>
            <i className='fab fa-discord'></i>
          </div>
          <span>Discord</span>
        </div>
        <div className={style.Wrapper}>
          <div className={style.Icon}>
            <i className='fab fa-youtube'></i>
          </div>
          <span>Twitter</span>
        </div>
        <div className={style.Wrapper}>
          <div className={style.Icon}>
            <i className='fab fa-linkedin'></i>
          </div>
          <span>Twitter</span>
        </div>
      </div>
      <div className={style.Copyright}>
      Copyright ©2022 All rights reserved | This template is made with  – {' '}
        <a
          target='_blank'
          rel='noreferrer'
          className='link'
          style={{ color: 'rgb(207, 53, 207)' }}
          href='https://hackerbro.in/'
        >
        ❤ by Hacker Bro Technologies
        </a>
      </div>
    </div>
  );
};

export default Social;
