import React, { useState } from 'react';
import style from './Login.module.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../actions/user.action';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import Cookie from 'universal-cookie';
import axios from 'axios';
import platform from 'platform';
import { ValidateEmail } from '../../utils/ValidateEmail';
let locName, countryCode;

const Login = () => {
  const getLocation = async (userId) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          axios
            .get(
              `https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=69767795771243a927f54d6744ecf024`
            )
            .then((res) => {
              locName = res.data.name;
              countryCode = res.data.sys.country;
            })
            .catch((err) => {
              console.log(err);
            });

          axios
            .get('https://api.ipify.org/?format=json')
            .then((res) => {
              const data = {
                user: userId,
                loginHistory: {
                  browserName: platform.name,
                  browserVersion: platform.version,
                  location: locName,
                  country: countryCode,
                  ip: res.data.ip,
                  os: `${platform.os.family} ${platform.os.version}`,
                },
                err: '',
              };
              axios
                .post('/loginhistory', data, {
                  headers: { 'Access-Control-Allow-Origin': '*' },
                  withCredentials: true,
                })
                .then((res) => {
                  window.location.href = '/dashboard';
                });
            })
            .catch((err) => {
              console.log(err);
            });
        },
        (error) => {
          let err;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              err = 'User Denied';
              break;
            case error.POSITION_UNAVAILABLE:
              err = 'Loc. info. is unavailable.';
              break;
            case error.TIMEOUT:
              err = 'Time Out';
              break;
            case error.UNKNOWN_ERROR:
              err = 'Unknown error';
              break;
            default:
              break;
          }

          axios.get('https://api.ipify.org/?format=json').then((res) => {
            const data = {
              user: userId,
              loginHistory: {
                browserName: platform.name,
                browserVersion: platform.version,
                location: '',
                country: '',
                ip: res.data.ip,
                os: `${platform.os.family} ${platform.os.version}`,
              },
              err: err,
            };

            axios
              .post('/loginhistory', data, {
                headers: { 'Access-Control-Allow-Origin': '*' },
                withCredentials: true,
              })
              .then((res) => {
                console.log(res.data);
                window.location.href = '/dashboard';
              });
          });
        }
      );
    } else {
      window.alert('Geolocation is not supported by this browser.');
    }
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const cookies = new Cookie();

  const loginstate = useSelector((state) => state.userLoginReducer);
  const { loading, success, message, token, curUser } = loginstate;

  const submitHandler = async (e) => {
    e.preventDefault();
    const user = { email: email, pass: password };
    if (email && password) {
      if (ValidateEmail(email)) {
        dispatch(userLogin(user));
      }
    } else {
      toast.error('Please fill the credentials!');
    }
  };

  if (!loading && success) {
    toast.success('Login Successful!');
    cookies.set('access_token', token);
    getLocation(curUser._id);
  } else {
    toast.error(message);
  }

  return (
    <div className={style.Login}>
      <p>Login to your account</p>
      <div className={style.section}>
        <p>Email</p>
        <input
          type='email'
          value={email}
          placeholder='hunter@hackerbro.in'
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className={style.section}>
        <p>Password</p>
        <input
          type='password'
          value={password}
          placeholder='Enter your password'
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className={style.Button} onClick={submitHandler}>
        {loading ? (
          <Oval
            ariaLabel='loading-indicator'
            height={30}
            width={30}
            strokeWidth={5}
            color='white'
            secondaryColor='purple'
          />
        ) : (
          'Login'
        )}
      </div>
      <div className={style.Bottom}>
        <span>Forgot your password?</span>
        <Link
          to='/forget-password'
          className='link'
          style={{ color: 'rgb(161, 35, 161)' }}
        >
          {' '}
          Reset it here.
        </Link>
      </div>
      <div className={style.Bottom}>
        <span>Are you new?</span>
        <Link
          to='/signup'
          className='link'
          style={{ color: 'rgb(161, 35, 161)' }}
        >
          {' '}
          Sign up.
        </Link>
      </div>
    </div>
  );
};

export default Login;
