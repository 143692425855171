export const userReportReducer = (state = { rep: [] }, action) => {
  switch (action.type) {
    case 'USER_REPORT_REQUEST':
      return {
        loading: true,
      };
    case 'USER_REPORT_SUCCESS':
      return {
        loading: false,
        success: true,
        rep: [...action.payload.user.reports],
      };
    case 'USER_REPORT_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    default:
      return state;
  }
};

export const addReportReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_REPORT_REQUEST':
      return {
        loading: true,
      };
    case 'ADD_REPORT_SUCCESS':
      return {
        loading: false,
        success: true,
      };
    case 'ADD_REPORT_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    default:
      return state;
  }
};

export const getReportByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_REPORT_BY_ID_REQUEST':
      return {
        loading: true,
      };
    case 'GET_REPORT_BY_ID_SUCCESS':
      return {
        loading: false,
        success: true,
        report: action.payload,
      };
    case 'GET_REPORT_BY_ID_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    default:
      return state;
  }
};

export const getAllReportReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_ALL_REPORT_REQUEST':
      return {
        loading: true,
      };
    case 'GET_ALL_REPORT_SUCCESS':
      return {
        loading: false,
        success: true,
        reports: action.payload,
      };
    case 'GET_ALL_REPORT_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    default:
      return state;
  }
};

export const updateReportReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_REPORT_REQUEST':
      return {
        loading: true,
      };
    case 'UPDATE_REPORT_SUCCESS':
      return {
        loading: false,
        success: true,
        message: action.payload,
      };
    case 'UPDATE_REPORT_ERROR':
      return {
        loading: false,
        success: false,
        message: action.payload,
      };
    case 'UPDATE_REPORT_RESET':
      return {};
    default:
      return state;
  }
};
